<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-notification type="is-danger" :active="error !== null" :closable="false">
      {{ error }}
    </b-notification>
    <b-field
      label="Prénom"
      :message="fields.firstname.error"
      :type="fields.firstname.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.firstname.value" type="text" />
    </b-field>
    <b-field
      label="Nom"
      :message="fields.lastname.error"
      :type="fields.lastname.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.lastname.value" type="text" />
    </b-field>
    <b-field
      label="Email"
      :message="fields.email.error"
      :type="fields.email.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.email.value" type="email" />
    </b-field>
    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading"> Modifier </b-button>
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EditAdministratorForm',

  props: {
    administrator: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      fields: {
        firstname: {
          value: '',
          error: null,
        },
        lastname: {
          value: '',
          error: null,
        },
        email: {
          value: '',
          error: null,
        },
      },
    }
  },

  watch: {
    administrator: {
      handler: 'load',
      immediate: true,
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    load() {
      this.fields.firstname.value = this.administrator.firstname
      this.fields.lastname.value = this.administrator.lastname
      this.fields.email.value = this.administrator.email
    },

    async submit() {
      this.error = null
      this.loading = true

      try {
        const administrator = await this.$services.administratorService.update(
          this.administrator.id,
          {
            firstname: this.fields.firstname.value,
            lastname: this.fields.lastname.value,
            email: this.fields.email.value,
          },
        )
        this.$emit('update:administrator', administrator)
        this.addToastMessage({
          text: 'Les modifications ont bien été enregistré.',
          type: 'is-success',
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
      }

      this.loading = false
    },
  },
}
</script>
