<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <ul>
        <li><a href="#">Dashboard</a></li>
        <li>
          <router-link :to="{ name: 'administrator.list' }"> Administrateurs </router-link>
        </li>
        <li class="is-active">
          <a href="#">
            {{ title }}
          </a>
        </li>
      </ul>
    </template>
    <div v-if="administrator" class="card">
      <div class="card-header">
        <h2 class="card-header-title">Modifier les informations</h2>
      </div>
      <div class="card-content">
        <edit-administrator-form :administrator.sync="administrator" />
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import EditAdministratorForm from './EditAdministratorForm.vue'

export default {
  name: 'EditAdministratorPage',

  components: {
    [PageLayout.name]: PageLayout,
    [EditAdministratorForm.name]: EditAdministratorForm,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      administrator: null,
    }
  },

  watch: {
    id: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.administrator
        ? `${this.administrator.firstname} ${this.administrator.lastname}`
        : '...'
    },
  },

  methods: {
    async load() {
      this.administrator = await this.$services.administratorService.getById(this.id)
    },
  },
}
</script>
