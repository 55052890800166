<template>
  <page-layout title="Administrateurs">
    <template v-slot:breadcrumb>
      <ul>
        <li><a href="#">Dashboard</a></li>
        <li class="is-active">
          <a href="#"> Administrateurs </a>
        </li>
      </ul>
    </template>
    <div class="box is-relative">
      <b-loading :is-full-page="false" :active="loading" />
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Dernière connexion</th>
            <th />
          </tr>
        </thead>
        <tbody v-show="!loading && administrators.length > 0">
          <tr v-for="administrator in administrators" :key="administrator.id">
            <td>{{ administrator.firstname }} {{ administrator.lastname }}</td>
            <td>19 Sep. 2019</td>
            <td>
              <div class="buttons are-small is-right">
                <b-button
                  type="is-primary"
                  tag="router-link"
                  :to="{
                    name: 'administrator.edit',
                    params: { id: administrator.id },
                  }"
                >
                  <b-icon icon="edit" />
                </b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="!loading && errorMessage" class="has-text-grey has-text-centered">
        {{ errorMessage }}
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'

export default {
  name: 'ListAdministratorPage',

  components: {
    [PageLayout.name]: PageLayout,
  },

  metaInfo() {
    return {
      title: 'Administrateurs',
    }
  },

  data() {
    return {
      loading: true,
      errorMessage: null,
      administrators: [],
    }
  },

  async mounted() {
    await this.fetch()
  },

  methods: {
    async fetch() {
      this.errorMessage = null
      this.loading = true

      try {
        this.administrators = await this.$services.administratorService.getAll()
      } catch (err) {
        this.errorMessage = 'Une erreur interne est survenue.'
        console.error({ err })
      }

      if (!this.errorMessage && this.administrators.length === 0) {
        this.errorMessage = 'Aucun administrateur'
      }

      this.loading = false
    },
  },
}
</script>
